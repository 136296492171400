:root {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color-scheme: light dark;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

.underline {
  box-shadow: inset 0 -14px 0 rgba(131, 94, 246, 0.35);
  display: inline-block;
  height: 2.5rem;
}

pre {
  overflow: auto;
}

::-moz-selection {
  background: rgba(131, 94, 246, 0.2);
}

::selection {
  background: rgba(131, 94, 246, 0.2);
}